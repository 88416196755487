import './App.css'; // Import the default styles
import React, { useState, useEffect } from 'react';
import { getNews, getVideo, getStory } from './services/api';
import { doc, getFirestore, collection, getDocs, getDoc, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import ReactQuill from 'react-quill'; // Import Quill component


const Groups = () => {
    return (
        <div className="page">
            Coming Soon!
        </div>
    );
}

const Profile = () => {
    return (
        <div className="page">
            Coming Soon!
        </div>
    );
}

const NewsForm = ({ onPageChange }) => {
    const [savedProfile, setSavedProfile] = useState(JSON.parse(localStorage.getItem('profile')) || {});
    const [headline, setHeadline] = useState('');
    const [description, setDescription] = useState('');
    const [story, setStory] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [updating, setUpdating] = useState(false);

    const redirect = () => {
        onPageChange("news"); // Update the current page in the App component
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setUpdating(true);

        try {
            let imageUrl = '';

            if (imageFile) {
                // Upload image to Firebase Storage
                const storage = getStorage();
                const imageRef = ref(storage, `images/${imageFile.name}`);
                await uploadBytes(imageRef, imageFile);
                imageUrl = await getDownloadURL(imageRef);
            }

            // Proceed with submitting the form data to Firestore
            const customNewsEntry = {
                type: 'custom',
                headline,
                lastModified: new Date().toISOString(),
                byline: savedProfile.Name,
                description,
                story,
                images: imageUrl ? [{ url: imageUrl }] : [],
                active: 0
            };

            const firestore = getFirestore();
            const newsCollection = collection(firestore, 'News');
            await addDoc(newsCollection, customNewsEntry);

            redirect();
        } catch (error) {
            console.error('Error adding news entry:', error);
            redirect();
        }

        setUpdating(false);
    };

    return (
        <div className="page">
            <form className="profile-section" onSubmit={handleSubmit}>
                <h2>Create News Entry</h2>
                <div>
                    <label className="title">Headline</label>
                    <input
                        type="text"
                        value={headline}
                        onChange={(e) => setHeadline(e.target.value)}
                        required
                    />
                </div>

                <div>
                    <label className="title">Author</label>
                    <div className="disabledField">{savedProfile.Name}</div>
                </div>
                <div>
                    <label className="title">Short Description</label>
                    <input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <div className="createStory">
                    <ReactQuill
                        value={story}
                        onChange={setStory}
                        required
                    />
                </div>

                <div>
                    <label className="title">Image</label>
                    <div className="fileUpload">
                        <input
                            required
                            type="file"
                            accept="image/*"
                            onChange={(e) => setImageFile(e.target.files[0])}
                        />
                    </div>
                </div>
                <button disabled={updating} type="submit">
                    {updating ? 'Submitting...' : 'Submit for Review'}
                </button>
            </form>
        </div>
    );
};

const News = ({ onPageChange }) => {
    var [news, setNews] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
      const [video, setVideo] = useState(null);
      const [story, setStory] = useState(null);
    const [loading, setLoading] = useState(null);
    

    const goToForm = (page) => {
        onPageChange(page); // Update the current page in the App component
    };

    useEffect(() => {
        // Function to fetch picks and update state
        const fetchNews = async () => {
            try {
                var news = await getNews();

                const firestore = getFirestore();

                const newsCollection = collection(firestore, 'News');

                // Get documents from the collection
                const newsArticles = await getDocs(newsCollection);

                // Process the snapshot
                var newsData = newsArticles.docs.map(doc => doc.data());
                
                newsData = newsData
                    .filter(article => article.active === 1);

                /*news.articles = news.articles
                    .filter(article => article.type === "HeadlineNews");*/

                // Add the custom news entry to the fetched news data
                news = {
                    ...news,
                    articles: [...news.articles, ...newsData]
                };
                const sortedArticles = Object.entries(news.articles)
                .sort(([, a], [, b]) => new Date(b.lastModified) - new Date(a.lastModified))
                .reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});
                


                // Update the state with the updated news data
                setNews({
                    ...news,
                    articles: sortedArticles
                });

            } catch (error) {
                console.error('Error fetching news:', error);
                fetchNews();
            }
        };

        fetchNews();

    }, []);

    useEffect(() => {
               

        const fetchVideo = async () => {
            console.log(selectedArticle);
            if ((selectedArticle !== null && selectedArticle !== 0) && news) {
                
                try {
                    const article = news.articles[selectedArticle];
                    console.log(article);
                    const videoData = await getVideo(article);
                    
                  setVideo(videoData);
                } catch (error) {
                    console.log(error);
                }
              }
            };

            fetchVideo();

        const fetchStory = async () => {

            if (selectedArticle !== null && news && news.articles[selectedArticle].type != "Media" && news.articles[selectedArticle].type) {
                try {
                  setLoading(true);
                  const article = news.articles[selectedArticle];
                  const thisstory = await getStory(article);

                    setStory(thisstory.headlines[0].story);
                 setLoading(false);
                } catch (error) {

                    setLoading(false);
                }
              }
            };

            fetchStory();
    }, [selectedArticle]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            
        });
    };

    const getDateDifference = (dateString) => {
      const givenDate = new Date(dateString);
      const now = new Date();
      const differenceInMilliseconds = now - givenDate;
      const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
      const differenceInHours =  Math.floor(differenceInMinutes / 60);
      if(differenceInMinutes < 0){
          return formatDate(dateString);
      }
      if(differenceInMinutes < 3){
          return "Just Now";
      }
      if(differenceInMinutes < 60){
          return differenceInMinutes + "m";
      }
      if(differenceInHours < 24){
        return differenceInHours + "h";
      }
      return formatDate(dateString);
      
    };

    const yesterday = () => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return yesterday;
    };

   const handleClick = (key) => {
        setSelectedArticle(key);
      };

  const nullify = () => {
      setSelectedArticle(null);
      setVideo(null);
      setStory(null);
  }

  if (selectedArticle !== null && news) {
    const article = news.articles[selectedArticle];
    return (
        <>
        <div className="backButton" onClick={() => nullify()}>
                <span className="reverse">&#10142;</span> &nbsp; Back to News
            </div>
                <div className="handles">
           
            <div className="page">
        
          <div className={video ? "singleArticle video" : "singleArticle"}>
                
        
                {video ? (
                <video width="500" controls autoPlay loop fullscreen="true">
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (
                       <>
                                {article.images[0]?.url && (
                                        <img src={article.images[0].url} alt="Article Image" />)}
                                </>
            )}
                <div className="credit">{article.images[0]?.credit ? `${article.images[0].credit}` : ''}</div>

                <div className="articleTitle">{article.headline}</div>
                        <div className="date">{getDateDifference(article.lastModified)}{article.byline ? ` - ${article.byline}` : video ? ' - Video' : ' - ESPN Staff'}</div>
                <div className="articleDescription">{article.description}</div>
                {loading && (
                <div className="articleStory">
                    
                    <div className="loader"></div>
                </div>
                )}
                {story &&  <div className="articleStory" dangerouslySetInnerHTML={{ __html: story }} />}
                {article.story &&  <div className="articleStory" dangerouslySetInnerHTML={{ __html: article.story }} />}
          </div>
          </div>
      </div>
      </>
    );
  }
  /*return(
       <div className="page">
      <div className="news">
        <h2>Recent News</h2>
          <div>Coming Soon</div>
      </div>
    </div>
  )*/
 return (
  <div className="page">
         <div className="news">
             <div className="goToNewsForm article">
                 <div className="headline" onClick={() => goToForm("newsForm")}>Click here to submit your own article</div>
             </div>
             <hr />
             {news && news.articles ? (
                 Object.entries({
                     // Insert the hardcoded article into the news object
                     /*hardcoded: {
                         headline: "Power Rankings After Week 13: See how the teams stack up!",
                         lastModified: new Date(2024, 12, 4, 15, 0, 0),
                         byline: "Benny Al-Ashari",
                         images: [{ url: "lions2.jpg" }],
                         type: "HeadlineNews",
                         pinned:true,
                         onClick: () => goToForm("nfl-teams") // Define the link behavior
                     },*/
                     ...news.articles // Spread the existing news articles after the hardcoded one
                 })
                     .sort(([keyA, rowA], [keyB, rowB]) => {
                         // Sort by pinned status first
                         if (rowA.pinned && !rowB.pinned) return -1;  // rowA is pinned, rowB is not
                         if (!rowA.pinned && rowB.pinned) return 1;   // rowA is not pinned, rowB is pinned

                         // If both are pinned or both are not pinned, sort by lastModified
                         return new Date(rowB.lastModified) - new Date(rowA.lastModified);
                     })
          .map(([key, row], index, array) => {
            const lastArticleDate = index > 0 ? array[index - 1][1].lastModified : null;
            const showDateHeader = lastArticleDate !== null && formatDate(lastArticleDate) !== formatDate(row.lastModified);

              return (
              <React.Fragment key={key}>
                {showDateHeader && false && (
                  <div className="dateHeadline">
                    {formatDate(yesterday()) === formatDate(row.lastModified) ? "Yesterday's News" : formatDate(new Date()) === formatDate(row.lastModified) ? "Today's News" : formatDate(row.lastModified)}
                  </div>
                )}
{/*                {(row.type === "HeadlineNews" || row.type === "Story!" || row.type === "custom") && (
                    <>*/}
                      <div className={`article ${row.type}`} onClick={() => row.onClick ? row.onClick() : handleClick(key)}>

                    {row.images[0]?.url && <img src={row.images[0].url} alt="Article Image" />}
                  <div className="content">
                    <div className="headline">{row.headline}</div>
                              <div className="date">
                                  
                      {getDateDifference(row.lastModified)}
                                  {row.byline ? ` - ${row.byline}` : row.type == 'Media' ? ' - Video' : ' - ESPN Staff'}
                                  {row.pinned ? " - Pinned" : ""}
                    </div>
                  </div>
                </div>
                {index > -1 && <hr />}
{/*                </>
                )}*/}
                
              </React.Fragment>
            );
          })
      ) : (
        <div className="loadingNews">Loading...</div>
      )}
    </div>
  </div>
);


}

const Article = (number) => {
    return(
        <div className="news">
            <h2>News Article #{number}</h2>
        </div>
    )
}

export { NewsForm, Groups, Profile, News, Article }; // Exporting Header and Footer components