import { getFirestore, collection, doc, getDoc, setDoc, query, where, getDocs } from 'firebase/firestore';



export const fetchData = async (selectedWeek) => {
    var buildUrl = `https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?dates=2024&`;
    if (selectedWeek > 18) {
        buildUrl += `seasontype=3&week=${selectedWeek - 18}`;
    }
    else if (selectedWeek > -1) {
        buildUrl += `seasontype=2&week=${selectedWeek}`;
    }
    else {
        buildUrl += `seasontype=1&week=${selectedWeek + 6}`;
        //buildUrl += `seasontype=1`;
    }
        try {
            const response = await fetch(
                buildUrl
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    
};
export const fetchPicks = async (selectedWeek, setPicks, setSelectedTeamIds, setSavedPicks) => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (!profile || !profile.Email) {
        return;
    }
    const user = profile.Email;

    try {
        const firestore = getFirestore();
        var picksCollection = collection(firestore, 'Picks'); 
        if(selectedWeek > 100){
            picksCollection = collection(firestore, 'B10Picks'); 
        }
        const q = query(picksCollection,
            where('Email', '==', user),
            where('Week', '==', selectedWeek)
        );
        const picksSnapshot = await getDocs(q);
        const picksData = picksSnapshot.docs.map(doc => doc.data());
        
        if (picksData.length === 0) {
            return;
        }
        const parsedPicks = JSON.parse(picksData[0].Picks);
        
        setPicks(parsedPicks);
        
        setSavedPicks(parsedPicks);
        
        setSelectedTeamIds(parsedPicks);
    } catch (error) {
        console.error(error);
    }
};


export const fetchAllPicks = async (type) => {
    try {
        const firestore = getFirestore(); // Initialize Firestore
        var picksCollection = collection(firestore, 'Picks'); 
        if(type == "ncaa"){
            picksCollection = collection(firestore, 'B10Picks'); 
        }
        // Reference to the 'Picks' collection
        const usersCollection = collection(firestore, 'Users'); // Reference to the 'Users' collection

        const picksSnapshot = await getDocs(picksCollection); // Fetch all picks

        const picksData = await Promise.all(picksSnapshot.docs.map(async (doc) => {
            const userData = await getUserData(usersCollection, doc.data().Email); // Get user data
            return { ...doc.data(), ...userData }; // Merge picks data with user data
        }));

        return picksData;
    } catch (error) {

    }
};

export const fetchAllPicksFromWeek = async (week) => {
    try {
        const firestore = getFirestore(); // Initialize Firestore
        const picksCollection = collection(firestore, 'Picks'); // Reference to the 'Picks' collection
        const usersCollection = collection(firestore, 'Users'); // Reference to the 'Users' collection

        const picksSnapshot = await getDocs(picksCollection); // Fetch all picks
        
        // Filter picks where the 'Week' field equals 1
        const filteredPicks = picksSnapshot.docs.filter(doc => doc.data().Week === week);

        const picksData = await Promise.all(filteredPicks.map(async (doc) => {
            const userData = await getUserData(usersCollection, doc.data().Email); // Get user data
            return { ...doc.data(), ...userData }; // Merge picks data with user data
        }));

        return picksData;
    } catch (error) {

    }
};


const getUserData = async (usersCollection, userEmail) => {
    const userDoc = await getDoc(doc(usersCollection, userEmail)); // Get user document
    if (userDoc.exists()) {
        return userDoc.data(); // Return user data if exists
    }
    return {}; // Return empty object if user does not exist
};

export const fetchUserProfile = async (google_profile, setProfile) => {
    try {
        // Assuming 'firestore' is your initialized Firestore instance
        const firestore = getFirestore();
        const docRef = doc(firestore, 'Users', google_profile.data.email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            // Document exists, fetch and return the user profile
            const data = docSnap.data();
            const newData = { ...data, Email: google_profile.data.email };
            setProfile(newData); // Set the profile state
            return newData;
        } else {
            // Document does not exist, create a new user
            const newUser = { Email: google_profile.data.email, Name: google_profile.data.name, Color: "darkyellow", Icon: "football" };
            await setDoc(docRef, newUser); // Create the new user document
            setProfile(newUser); // Set the profile state
            return newUser;
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error gracefully, e.g., display a message to the user
    }
};

export const fetchUserProfile2 = async (email, setProfile) => {

    try {
        // Assuming 'firestore' is your initialized Firestore instance
        const firestore = getFirestore();
        const docRef = doc(firestore, 'Users', email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            // Document exists, fetch and return the user profile
            const data = docSnap.data();
            const newData = { ...data, Email: email };
            setProfile(newData); // Set the profile state
            return newData;
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error gracefully, e.g., display a message to the user
    }
};


export const savePicks = async (picks, user, week) => {
    try {
        const firestore = getFirestore(); // Initialize Firestore
        
        var picksCollection = collection(firestore, 'Picks'); 
        if(week > 100){
            picksCollection = collection(firestore, 'B10Picks'); 
        }
        

        // Query to check if the user already has picks for the given week
        const q = query(picksCollection,
            where('Email', '==', user), // Filter by email
            where('Week', '==', week) // Filter by week
        );

        const picksSnapshot = await getDocs(q); // Fetch picks collection
        const picksDocs = picksSnapshot.docs;

        if (picksDocs.length === 0) {
            // If no picks found for the user and week, create a new document
            await setDoc(doc(picksCollection), { Email: user, Week: week, Picks: JSON.stringify(picks) });
        } else {
            // If picks already exist for the user and week, update the existing document
            const docId = picksDocs[0].id; // Get the document ID
            await setDoc(doc(picksCollection, docId), { Email: user, Week: week, Picks: JSON.stringify(picks) });
        }

    } catch (error) {
        console.log(error);
    }
};

export const saveB10Picks = async (picks, user, week) => {
    try {
        const firestore = getFirestore(); // Initialize Firestore
        const picksCollection = collection(firestore, 'Picks'); // Reference to the 'Picks' collection

        // Query to check if the user already has picks for the given week
        const q = query(picksCollection,
            where('Email', '==', user), // Filter by email
            where('Week', '==', week) // Filter by week
        );

        const picksSnapshot = await getDocs(q); // Fetch picks collection
        const picksDocs = picksSnapshot.docs;

        if (picksDocs.length === 0) {
            // If no picks found for the user and week, create a new document
            await setDoc(doc(picksCollection), { Email: user, Week: week, Picks: JSON.stringify(picks) });
        } else {
            // If picks already exist for the user and week, update the existing document
            const docId = picksDocs[0].id; // Get the document ID
            await setDoc(doc(picksCollection, docId), { Email: user, Week: week, Picks: JSON.stringify(picks) });
        }

    } catch (error) {

    }
};

export const getNews = async () => {
        try {
            const response = await fetch(`https://site.api.espn.com/apis/site/v2/sports/football/nfl/news?limit=1000`);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
};

    export const getVideo = async (link) => {
    try {
        let href = link.links.api.self.href;
        console.log(href);
        // Convert http to https if needed
        if (href.startsWith('http://')) {
            href = href.replace('http://', 'https://');
        }

        const response = await fetch(href);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const source = data.videos[0].links.source.HD.href;
        return source;
    } catch (error) {
        return null;
    }
};

export const getStory = async (link) => {
    try {
        let href = link.links.api.self.href;
        console.log(link);
        // Convert http to https if needed
        if (href.startsWith('http://')) {
            href = href.replace('http://', 'https://');
        }

        const response = await fetch(href);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return null;
    }
};
