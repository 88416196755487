import React, { useState, useEffect, useCallback } from 'react';
import './App.css'; // Import the default styles
import { fetchAllPicks, fetchData } from './services/api';
import { doc, getFirestore, collection, getDocs, getDoc } from 'firebase/firestore';

const Leaders = ({ onPageChange }) => {
    const [data, setPicks] = useState([]); // State to store the fetched data
    const [board, setBoard] = useState({}); // State to store the leaderboard data
    const [loading, setLoading] = useState(true); // State to store the leaderboard data
    const [displayNames, setDisplayNames] = useState({}); // State to store the leaderboard data
    const [pics, setPics] = useState({}); // State to store the leaderboard data
    const [colors, setColors] = useState({}); // State to store the leaderboard data
    const [lastUpdateDate, setLastUpdateDate] = useState(""); 
    const [lastUpdateWeek, setLastUpdateWeek] = useState(""); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const firestore = getFirestore();

                const usersCollection = collection(firestore, 'Users');

                // Get documents from the collection
                const snapshot = await getDocs(usersCollection);

                // Process the snapshot
                const fetchedData = snapshot.docs.map(doc => doc.data());

                setBoard(fetchedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchLastUpdate = async () => {
            try {
                const firestore = getFirestore();
                const connect = doc(firestore, 'LastUpdated', 'WeekPicks');
                const docSnapshot = await getDoc(connect);
                const Data = docSnapshot.data();
                const lud = Data["Date"];
                const luw = Data["Week"];
                setLastUpdateDate(lud);
                setLastUpdateWeek(luw);
            } catch (error) {
                console.error('Error fetching last update:', error);
            }
        };
        fetchLastUpdate();
        fetchData(); // Call the function to fetch data
    }, []);
/*

    // Memoize the fetchData function
    const fetchGameData = useCallback(async (selectedWeek) => {
        try {
            const response = await fetchData(selectedWeek);
            return response.events;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    }, []);

    useEffect(() => {
        // Function to fetch picks and update state
        const fetchPicks = async () => {
            try {
                const picksData = await fetchAllPicks();
                setPicks(picksData);
            } catch (error) {
                console.error('Error fetching picks:', error);
            }
        };

        // Call the fetchPicks function when the component mounts
        fetchPicks();

    }, []); // Empty dependency array to ensure effect runs only once after initial render

    
    useEffect(() => {
        const fetchAndProcessData = async () => {
            setLoading(true);
            const start = 17;
            const weeks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
            const weeksToFetch = weeks.slice(start);

            const allGamesData = {};

            for (const selectedWeek of weeksToFetch) {
                const eventData = await fetchGameData(selectedWeek);

                for (const [key, row] of Object.entries(eventData)) {
                    const game = row.competitions[0];
                    if (game.competitors[0].winner) {
                        allGamesData[game.id] = game.competitors[0].team.name;
                    } else if (game.competitors[1].winner) {
                        allGamesData[game.id] = game.competitors[1].team.name;
                    }
                }
            }

            // Process data and update leaderboard
            const newBoard = {};
            const displayNames = {};
            const displayPics = {};
            const displayColors = {};

            for (const [key, row] of Object.entries(data)) {
                const item = row;
                const user = item.Email;

                if (item.userName === "") {
                    displayNames[user] = user;
                }
                else {
                    displayNames[user] = item.Name;
                }
                if (!item.Icon || item.Icon === "") {
                    displayPics[user] = "football.png";
                } else {
                    displayPics[user] = item.Icon;
                }
                if (!item.Color || item.Color === "") {
                    displayColors[user] = "football.png";
                } else {
                    displayColors[user] = item.Color;
                }

                let picks;

                try {
                    picks = JSON.parse(item.Picks);
                } catch (error) {
                    // Handle the error or provide a fallback value if parsing fails
                    picks = {}; // Default value or appropriate fallback
                }

                if (!newBoard[user]) {
                    newBoard[user] = { points: 0, correct: 0, wrong: 0 }; // Initialize as an object with properties
                }

                var total = 0;
                var correct = 0;
                var wrong = 0;
                for (const [matchupID, pick] of Object.entries(picks)) {
                    if (allGamesData && allGamesData[matchupID]) {
                        if (allGamesData[matchupID] === pick.pick) {
                            total += pick.val;
                            correct++;
                        }
                        else {
                            wrong++;
                        }
                    }
                }

                newBoard[user]["points"] += total;
                newBoard[user]["correct"] += correct;
                newBoard[user]["wrong"] += wrong;
            }

            if (Object.keys(newBoard).length !== 0) {
                setBoard(newBoard);
                setDisplayNames(displayNames);
                setPics(displayPics);
                setColors(displayColors);
                setLoading(false);
            } else if (Object.keys(board).length !== 0) {
                setLoading(false);
            }
        };

        fetchAndProcessData();

    }, [data, fetchGameData]); // Dependency on 'data' and 'fetchGameData'
    */
    const currentDateTime = new Date().toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });

    //var lastScore = -1;

    {/*{loading && board !== {} ? (
                            <tr><td colSpan="4">Loading...</td></tr>
                        ) : (
                            Object.entries(board)
                                .sort(([, a], [, b]) => b.points - a.points) // Sort by points in descending order
                                .map(([user, score], index, array) => {
                                    const rank = index + 1; // Adjust rank to start from 1
                                    const lastScore = index > 0 ? array[index - 1][1].points : null; // Initialize lastScore properly
                                    const isTied = lastScore !== null && lastScore === score.points;
                                    return (
                                        <tr key={user}>
                                            <td>{!isTied ? rank : null}</td>
                                            <td><span><span className={colors[user]}><img alt="Profile" src={pics[user] + ".png"} /></span>{displayNames[user]}</span></td>
                                            <td>{score.correct}-{score.wrong}</td>
                                            <td>{score.points}</td>
                                        </tr>
                                    );
                                })
                        )}*/}

    return (
        <div className="page">
            <div className="leaderboard">

                <h1>Leaderboard</h1>
                <div className="leaderButtons">
                    <div
                        className="leaderButton"
                        onClick={() => onPageChange("weekly")}
                    >
                        <span>Weekly</span>
                    </div>
                    <div
                        className="leaderButton current"

                    >
                        <span>Season</span>
                    </div>
                </div>{lastUpdateWeek > 0 || true ? (
                    <div>
                <h2>2024/25 Season</h2>
                 <div className="description">{lastUpdateWeek > 0 ? `Games through Week ${lastUpdateWeek} - Updated on ${lastUpdateDate}` : ""}</div>
                <table>
                    <thead>
                        <tr>
                            <th>RK</th>
                            <th>PLAYER</th>
                            <th>PTS</th>
                            <th>REC</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && Object.keys(board).length === 0 ? (
                            <tr><td colSpan="4">Loading...</td></tr>
                        ) : (
                            Object.values(board)
                                //.filter(user => user["Points"] != null) // Filter users with points
                                .sort((a, b) => {
                                    // First, sort by "Points", treating missing values as 0
                                    const pointsA = a["Points"] !== undefined && a["Points"] !== null ? a["Points"] : 0;
                                    const pointsB = b["Points"] !== undefined && b["Points"] !== null ? b["Points"] : 0;
                                    const pointsComparison = pointsB - pointsA;
                                    if (pointsComparison !== 0) {
                                        return pointsComparison;
                                    }

                                    // Sort by "Correct" next
                                    const correctA = a["Correct"] !== undefined && a["Correct"] !== null ? a["Correct"] : Number.MIN_SAFE_INTEGER;
                                    const correctB = b["Correct"] !== undefined && b["Correct"] !== null ? b["Correct"] : Number.MIN_SAFE_INTEGER;
                                    const correctComparison = correctB - correctA;

                                    if (correctComparison !== 0) {
                                        return correctComparison;
                                    }

                                    // Finally, sort by "Wrong"
                                    const wrongA = a["Wrong"] !== undefined && a["Wrong"] !== null ? a["Wrong"] : Number.MIN_SAFE_INTEGER;
                                    const wrongB = b["Wrong"] !== undefined && b["Wrong"] !== null ? b["Wrong"] : Number.MIN_SAFE_INTEGER;
                                    const wrongComparison = wrongA - wrongB;
                                    if (wrongComparison !== 0) {
                                        return wrongComparison;
                                    }

                                    // If points are equal, sort by "Order"
                                    const orderA = a["Order"] !== undefined && a["Order"] !== null ? a["Order"] : Number.MIN_SAFE_INTEGER;
                                    const orderB = b["Order"] !== undefined && b["Order"] !== null ? b["Order"] : Number.MIN_SAFE_INTEGER;
                                    const orderComparison = orderB - orderA;
                                    if (orderComparison !== 0) {
                                        return orderComparison;
                                    }

                                    // If both points and order are equal, sort by "Icon"
                                    return a["Name"].localeCompare(b["Name"]);
                                })
                                .map((user, index, array) => {
                                    const rank = index === 0 ? 1 : array[index - 1]["Points"] > user["Points"] ? index + 1 : index + 1;
                                    //const lastScore = index > 0 ? array[index - 1][1].points : null; // Initialize lastScore properly

                                    var isTied = index === 0
                                        ? false
                                        : (array[index - 1]["Points"] == user["Points"])
                                        || (array[index - 1]["Points"] === 0 && user["Points"] === undefined)
                                        || (array[index - 1]["Points"] === undefined && user["Points"] === 0);

                                    //const isTied = false;
                                    return (
                                        <tr key={user["Name"]}>
                                            <td>{!isTied ? rank : null}</td>
                                            <td>
                                                <span>
                                                    <span className={user["Color"]}>
                                                        <img alt="Profile" src={`${user["Icon"]}.png`} />
                                                    </span>
                                                    {user["Name"]}
                                                </span>
                                            </td>
                                            <td>{user["Points"]||0}</td>
                                            <td>{user["Correct"] || 0}-{user["Wrong"]||0}</td>
                                            
                                        </tr>
                                    );
                                })
                        )}
                    </tbody>


                </table>
                       
                </div>
                )
                    : <div className="">Loading...</div>}
            </div>

        </div>
    );
};


const Weekly = ({ onPageChange }) => {
    const [data, setPicks] = useState([]); // State to store the fetched data
    const [board, setBoard] = useState({}); // State to store the leaderboard data
    const [week, setWeek] = useState(0); // State to store the leaderboard data
    const [displayNames, setDisplayNames] = useState({}); // State to store the leaderboard data
    const [pics, setPics] = useState({}); // State to store the leaderboard data
    const [colors, setColors] = useState({}); // State to store the leaderboard data



    // Memoize the fetchData function
    const fetchGameData = useCallback(async (selectedWeek) => {
        try {
            const response = await fetchData(selectedWeek);
            return response.events;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    }, []);

    useEffect(() => {
        // Function to fetch picks and update state
        const fetchPicks = async () => {
            try {
                const picksData = await fetchAllPicks();
                setPicks(picksData);
            } catch (error) {
                console.error('Error fetching picks:', error);
            }
        };

        // Call the fetchPicks function when the component mounts
        fetchPicks();

    }, []); // Empty dependency array to ensure effect runs only once after initial render

    const dates = getDates();
    const thisWeek = getWeekIndex(dates);

    useEffect(() => {
        const fetchAndProcessData = async () => {

            if (week === 0) {
                setWeek(getWeekIndex(dates));
            }
            const weeksToFetch = [week];
            const allGamesData = {};

            for (const selectedWeek of weeksToFetch) {
                const eventData = await fetchGameData(selectedWeek);
                for (const [key, row] of Object.entries(eventData ?? {})) {
                    const game = row.competitions[0];
                    allGamesData[game.id] = {};

                    if (row.week.number == week || row.week.number + 18 == week) {
                        if (game.competitors[0].winner) {
                            allGamesData[game.id]["team"] = game.competitors[0].team.name;
                        } else if (game.competitors[1].winner) {
                            allGamesData[game.id]["team"] = game.competitors[1].team.name;
                        }
                    }
                }
            }


            // Process data and update leaderboard
            const newBoard = {};
            const displayNames = {};
            const displayPics = {};
            const displayColors = {};

            for (const [key, row] of Object.entries(data ?? {})) {
                const item = row;
                const user = item.Email;

                if (item.Name === "") {
                    displayNames[user] = user;
                }
                else {
                    displayNames[user] = item.Name;
                }
                if (!item.Icon || item.Icon === "") {
                    displayPics[user] = "football";
                } else {
                    displayPics[user] = item.Icon;
                }
                if (!item.Color || item.Color === "") {
                    displayColors[user] = "football.png";
                } else {
                    displayColors[user] = item.Color;
                }

                let picks;

                try {
                    picks = JSON.parse(item.Picks);
                } catch (error) {
                    // Handle the error or provide a fallback value if parsing fails
                    picks = {}; // Default value or appropriate fallback
                }

                if (!newBoard[user]) {
                    newBoard[user] = { points: 0, correct: 0, wrong: 0 }; // Initialize as an object with properties
                }

                var total = 0;
                var correct = 0;
                var wrong = 0;

                for (const [matchupID, pick] of Object.entries(picks ?? {})) {
                    if (allGamesData && allGamesData[matchupID]) {
                        if (allGamesData[matchupID]["team"] && allGamesData[matchupID][item.Email] != "Scored") {
                            if (allGamesData[matchupID]["team"] === pick.pick) {
                                allGamesData[matchupID][item.Email] = "Scored";
                                total += pick.val;
                                correct++;
                            }
                            else {
                                allGamesData[matchupID][item.Email] = "Scored";
                                wrong++;
                            }
                        }
                    }
                }

                newBoard[user]["points"] += total*1;
                newBoard[user]["correct"] += correct * 1;
                newBoard[user]["wrong"] += wrong * 1;
                newBoard[user]["total"] = item.Points * 1;
            }

            setBoard(newBoard);
            setDisplayNames(displayNames);
            setPics(displayPics);
            setColors(displayColors);
        };

        fetchAndProcessData();

    }, [/*data, fetchGameData, */week, dates]); // Dependency on 'data' and 'fetchGameData'

    const currentDateTime = new Date().toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });

    const onChange = (index) => {
        console.log(index);
        setWeek(index + 1);
    }

    return (
        <div className="page">
            <div className="leaderboard">
                <h1>Leaderboard</h1>
                <div className="leaderButtons">
                    <div className="leaderButton current">
                        <span>Weekly</span>
                    </div>
                    <div
                        className="leaderButton"
                        onClick={() => onPageChange("leaderboard")}
                    >
                        <span>Season</span>
                    </div>
                </div>
                {Object.keys(board).length > -1 ? (
                    <>
                        <select value={week - 1} onChange={(e) => onChange(parseInt(e.target.value))}>
                            {dates.filter((date, index) => index < thisWeek).map((dateRange, index) => (
                                <option key={index} value={index}>Week {index + 1}</option>
                            ))}
                        </select>
                        <table>
                            <thead>
                                <tr>
                                    <th>RK</th>
                                    <th>PLAYER</th>
                                    <th>PTS</th>
                                    <th>REC</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(board)
                                    .sort((a, b) => {

                                        const pointsA = a[1]["points"] !== undefined && a[1]["points"] !== null ? a[1]["points"] : 0;
                                        const pointsB = b[1]["points"] !== undefined && b[1]["points"] !== null ? b[1]["points"] : 0;
                                        const pointsComparison = pointsB - pointsA;
                                        if (pointsComparison !== 0) {
                                            return pointsComparison;
                                        }

                                        const correctA = a[1]["correct"] !== undefined && a[1]["correct"] !== null ? a[1]["correct"] : 0;
                                        const correctB = b[1]["correct"] !== undefined && b[1]["correct"] !== null ? b[1]["correct"] : 0;
                                        const correctComparison = correctB - correctA;
                                        if (correctComparison !== 0) {
                                            return correctComparison;
                                        }

                                        const wrongA = a[1]["wrong"] !== undefined && a[1]["wrong"] !== null ? a[1]["wrong"] : 0;
                                        const wrongB = b[1]["wrong"] !== undefined && b[1]["wrong"] !== null ? b[1]["wrong"] : 0;
                                        const wrongComparison = wrongA - wrongB;
                                        if (wrongComparison !== 0) {
                                            return wrongComparison;
                                        }

                                        const totalA = a[1]["total"] !== undefined && a[1]["total"] !== null ? a[1]["total"] : 0;
                                        const totalB = b[1]["total"] !== undefined && b[1]["total"] !== null ? b[1]["total"] : 0;
                                        const totalC = totalB - totalA;
                                        if (totalC !== 0) {
                                            return totalC;
                                        }
                                    })
                                    .map(([user, score], index, array) => {
                                        const rank = index + 1; // Adjust rank to start from 1
                                        const lastScore = index > 0 ? array[index - 1][1].points : null; // Initialize lastScore properly
                                        const lastC = index > 0 ? array[index - 1][1].correct : null; // Initialize lastScore properly
                                        const lastW = index > 0 ? array[index - 1][1].wrong : null; // Initialize lastScore properly
                                        const isTied = lastScore !== null && lastScore === score.points && lastC !== null && lastC === score.correct && lastW !== null && lastW === score.wrong;
                                        return (
                                            <tr key={user}>
                                                <td>{!isTied ? rank : null}</td>
                                                <td>
                                                    <span>
                                                        <span className={colors[user]}>
                                                            <img alt="Profile" src={pics[user] + ".png"} />
                                                        </span>
                                                        {displayNames[user]}
                                                    </span>
                                                </td>
                                                <td>{score.points*1}</td>
                                                <td>{score.correct}-{score.wrong}</td>
                                                
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        <div className="description">Updated on {currentDateTime}</div>
                    </>
                ) : <div className="">Loading...</div>}
            </div>
        </div>
    );

};

const getWeekIndex = (dates) => {

    const currentDate = new Date();
    const weeks = [];
    dates.forEach((dateRange, index) => {
        const [startDateStr, endDateStr] = dateRange.split(' - ');
        var year = "2024";
        if (startDateStr.includes("Jan") || startDateStr.includes("Feb")) {
            year = "2025";
        }
        const startDate = new Date(startDateStr + ", " + year);
        if (endDateStr.includes("Jan") || endDateStr.includes("Feb")) {
            year = "2025";
        }
        else {
            year = "2024";
        }
        const endDate = new Date(endDateStr + ", " + year);
        endDate.setDate(endDate.getDate() + 1); // Including end date
        weeks.push({ startDate, endDate });
    });

    var weekIndex = weeks.findIndex((week) => currentDate >= week.startDate && currentDate <= week.endDate);

    if (weekIndex === -1) {
        weekIndex = 0;
    }

    return weekIndex + 1;
}

const getDates = () => {
    const dates = [
    'Sep 3 - Sep 11',
    'Sep 12 - Sep 18',
    'Sep 19 - Sep 25',
    'Sep 26 - Oct 2',
    'Oct 3 - Oct 9',
    'Oct 10 - Oct 16',
    'Oct 17 - Oct 23',
    'Oct 24 - Oct 30',
    'Oct 31 - Nov 6',
    'Nov 7 - Nov 13',
    'Nov 14 - Nov 20',
    'Nov 21 - Nov 27',
    'Nov 28 - Dec 4',
    'Dec 5 - Dec 11',
    'Dec 12 - Dec 18',
    'Dec 19 - Dec 25',
    'Dec 26 - Jan 1',
            'Jan 2 - Jan 8',
            'Jan 9 - Jan 15',
            'Jan 16 - Jan 22',
            'Jan 23 - Jan 29',
            'May 3 - May 5',
            'Jan 30 - Apr 28'
];

    return dates;
}

export { Leaders, Weekly };
