import React, { useState, useEffect } from 'react';
import { fetchAllPicksFromWeek } from '../../services/api';

export const MatchupPicks = ({ week, matchup, season }) => {
    const [picksData, setPicksData] = useState([]);
    const [homeCount, setHomeCount] = useState(0);
    const [awayCount, setAwayCount] = useState(0);
    console.log(season);
    const homeTeam = matchup.competitions[0]?.competitors[0]?.team.name || 'Team Not Available';
    const awayTeam = matchup.competitions[0]?.competitors[1]?.team.name || 'Team Not Available';

    useEffect(() => {
        const fetchData = async () => {
            try {
                let realWeek = week;
                if (season == 3) {
                    realWeek = week + 18;
                }
                if (matchup.id === "401671610") {
                    realWeek -= 6;
                }

                const data = await fetchAllPicksFromWeek(realWeek);

                // Sort data by 'order' field first, then by 'Name'
                const sortedData = data.sort((a, b) => {
                    const orderA = a.Order || 0;
                    const orderB = b.Order || 0;
                    if (orderA !== orderB) return orderB - orderA;

                    const nameA = a.Name.toUpperCase();
                    const nameB = b.Name.toUpperCase();
                    return nameA.localeCompare(nameB);
                });

                // Deduplicate data by Email
                const emailSet = new Set();
                const deduplicatedData = sortedData.filter(item => {
                    if (emailSet.has(item.Email)) return false;
                    emailSet.add(item.Email);
                    return true;
                });

                setPicksData(deduplicatedData);
            } catch (error) {
                console.error("Error fetching picks data:", error);
            }
        };

        fetchData();
    }, [week]);

    const extractFieldValues = (fieldName, shouldParse) => {
        return picksData.map(item => {
            const value = item[fieldName];
            return shouldParse ? JSON.parse(value) : value;
        });
    };

    const fieldValueArray = extractFieldValues('Picks', true);
    const usersValueArray = extractFieldValues('Name', false);

    useEffect(() => {
        let homeCount = 0;
        let awayCount = 0;

        fieldValueArray.forEach((value, key) => {
            if (value[matchup.id] && homeTeam === value[matchup.id].pick) {
                homeCount++;
            } else if (value[matchup.id] && awayTeam === value[matchup.id].pick) {
                awayCount++;
            }
        });

        setHomeCount(homeCount);
        setAwayCount(awayCount);
    }, [fieldValueArray, homeTeam, awayTeam, matchup.id]);

    return (
        <div className="matchup-picks">
            {awayCount + homeCount > 0 && <h2>Who Picked Who?</h2>}
            {awayCount + homeCount > 0 ? (
                <div className="counts">
                    <div className="home">
                        {awayCount > 0 && (
                            <div
                                className="count"
                                style={{ width: `calc(${100 * awayCount / (awayCount + homeCount)}% - 6px)` }}
                            >
                                {awayCount}
                            </div>
                        )}
                        {Object.entries(fieldValueArray).map(([key, value]) =>
                            value[matchup.id] && awayTeam === value[matchup.id].pick ? (
                                <div key={key}>
                                    {usersValueArray[key]}
                                    <span className={picksData[key]?.Color}>
                                        <img
                                            alt="Profile"
                                            src={`${picksData[key]?.Icon}.png`}
                                        />
                                    </span>
                                </div>
                            ) : null
                        )}
                    </div>
                    <div className="away">
                        {homeCount > 0 && (
                            <div
                                className="count"
                                style={{ width: `calc(${100 * homeCount / (awayCount + homeCount)}% - 6px)` }}
                            >
                                {homeCount}
                            </div>
                        )}
                        {Object.entries(fieldValueArray).map(([key, value]) =>
                            value[matchup.id] && homeTeam === value[matchup.id].pick ? (
                                <div key={key}>
                                    <span className={picksData[key]?.Color}>
                                        <img
                                            alt="Profile"
                                            src={`${picksData[key]?.Icon}.png`}
                                        />
                                    </span>
                                    {usersValueArray[key]}
                                </div>
                            ) : null
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
};
